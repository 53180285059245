import { useTranslations } from 'next-intl'
import type { FC } from 'react'

import { ButtonPill, IconDownload } from '@fortum/elemental-ui'

import { downloadCSV } from '@/logged-in/utils/commonUtils'
import { usePriceToString } from '@/shared/hooks/usePriceToStringFormatter'
import { useLocale } from '@/shared/locale'
import { sendGTMEvent } from '@/shared/utils/sendGTMEvent'
import { withNetworkErrorHandling } from '@/shared/utils/withNetworkErrorHandling'

import generateTranslatedSpotPriceCSV from '../actions'
import type { SpotEntry } from '../commons'
import { useStore } from '../StoreProvider'

export type DownloadButtonProps = {
  data: SpotEntry[]
}

export const DownloadButton: FC<DownloadButtonProps> = ({ data }) => {
  const t = useTranslations('spotPrices')
  const averagePrice = useStore((s) => s.averagePrice)
  const activeDate = useStore((s) => s.activeDate)
  const locale = useLocale()
  const priceArea = useStore((s) => s.priceArea)
  const isVatIncluded = useStore((s) => s.isVatIncluded)
  const { formatUnit } = usePriceToString()

  return (
    <ButtonPill
      data-testid="download-button"
      status="secondary"
      variant="condensed"
      leftIcon={IconDownload}
      alignSelf={{ default: 'stretch', s: 'flex-end' }}
      height="40px"
      onClick={async () => {
        if (data.length === 0) {
          return null
        }
        const mappedData = data.map((entry) => {
          return {
            ...entry,
            unit: formatUnit(entry.unit),
          }
        })

        const { networkError, actionResult: csvString } = await withNetworkErrorHandling(
          generateTranslatedSpotPriceCSV,
          mappedData,
          averagePrice,
          locale,
          priceArea,
          isVatIncluded,
        )

        if (networkError || !csvString) {
          // TODO: Display error UI
        } else {
          downloadCSV('Spotprice', csvString, activeDate, priceArea, locale)
          sendGTMEvent({
            event: 'graph_interaction',
            graph_title: 'Spot price',
            click_element: 'download_button',
            click_text: t('download'),
          })
        }
      }}
    >
      {t('download')}
    </ButtonPill>
  )
}
