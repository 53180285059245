import type { FC, ReactNode } from 'react'
import React from 'react'

import { Box, ContentText, FontSize, spacing } from '@fortum/elemental-ui'

type DuoLineProps = {
  label: string
  icon: ReactNode
}

export const DuoLine: FC<DuoLineProps> = ({ label, icon }) => {
  return (
    <Box display="flex" alignItems="center" gap={spacing.xxxs}>
      {icon}
      <ContentText
        data-testid="daily-average-price"
        display="flex"
        gap={spacing.xxxxs}
        size={FontSize.s}
      >
        {label}
      </ContentText>
    </Box>
  )
}
