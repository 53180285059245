import type { FC } from 'react'
import React from 'react'

import type { KPIProps } from '@/shared/components/SpotPrices/KPIs/KPI'

type IconTriangleProps = {
  direction: NonNullable<KPIProps['direction']>
  mappedColor: string | undefined
  withoutOffset?: boolean
}

export const TriangleIcon: FC<IconTriangleProps> = ({ direction, mappedColor, withoutOffset }) => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{
      marginTop: withoutOffset ? undefined : '2px',
      transform: direction === 'up' ? 'rotate(180deg)' : undefined,
    }}
  >
    <path d="M6 9.5L0.803848 0.5L11.1962 0.5L6 9.5Z" fill={mappedColor} />
  </svg>
)
