import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import type React from 'react'

import { Box } from '@fortum/elemental-ui'

import { CrosshairId } from '@/shared/components/SpotPrices/commons'

export type ChartProps = {
  config: Highcharts.Options
}

const CrosshairContainer = () => (
  <Box id={CrosshairId} display={{ m: 'none' }} style={{ pointerEvents: 'none' }} />
)

export const Chart: React.FC<ChartProps> = ({ config }) => (
  <Box position="relative">
    <CrosshairContainer />
    <HighchartsReact highcharts={Highcharts} options={config} />
  </Box>
)

export default Chart
