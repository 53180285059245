import { DateTime, Duration } from 'luxon'
import { useTranslations } from 'next-intl'
import type React from 'react'
import { useEffect, useState } from 'react'

import type { DateBrowserMode } from '@fortum/elemental-ui/DateBrowser/types'

import { DATE_COMMON_FORMAT } from '@/open-web/utils/constants'
import { useCommonDateFormatter } from '@/shared/hooks/useCommonDateFormatter'
import { useLocale } from '@/shared/locale'
import { sendGTMEvent } from '@/shared/utils/sendGTMEvent'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import * as $ from './styled'
import { dateBrowserAriaLabels } from '../../../../features/logged-in/components/Energy/commons'
import type { GranularityOption } from '../commons'
import { useStore } from '../StoreProvider'

type DateBrowserProps = {
  disabled: boolean
  granularity: GranularityOption
}

const MAPPING = {
  Day: 'date',
  Month: 'month',
  Year: 'year',
} satisfies Record<GranularityOption, DateBrowserMode>

export const DateBrowser: React.FC<DateBrowserProps> = ({ disabled, granularity }) => {
  const t = useTranslations('consumption')
  const setActiveDate = useStore((s) => s.setActiveDate)
  const activeDate = useStore((s) => s.activeDate)
  const locale = useLocale()
  const priceArea = useStore((s) => s.priceArea)
  const { formatDate } = useCommonDateFormatter()

  const [previousGranularity, setPreviousGranularity] = useState<GranularityOption | null>(null)

  useEffect(() => {
    if (granularity !== previousGranularity && previousGranularity === 'Year') {
      const timeZone = getTimeZoneForPriceAreaCode(priceArea)
      const currentDate = DateTime.now().setZone(timeZone)
      setActiveDate(currentDate)
    }
    setPreviousGranularity(granularity)
  }, [granularity, previousGranularity, priceArea, setActiveDate])

  const handleDateChange = (date: Date | null) => {
    if (disabled) {
      return
    }
    const newActiveDate = (date ? DateTime.fromJSDate(date) : activeDate).setZone(
      getTimeZoneForPriceAreaCode(priceArea),
    )

    setActiveDate(newActiveDate)
    sendGTMEvent({
      event: 'graph_interaction',
      graph_title: 'Spot price',
      click_element: 'date_browser',
      click_text: newActiveDate.toISO()!,
    })
  }

  const maxDate = DateTime.now()
    .plus(Duration.fromObject({ day: 1 }))
    .toJSDate()
  let minDate = DateTime.now()
    .minus(Duration.fromObject({ days: 30 }))
    .toJSDate()

  if (granularity === 'Year') {
    minDate = DateTime.now()
      .minus(Duration.fromObject({ years: 3 }))
      .toJSDate()
  }

  return (
    <$.DateBrowser
      locale={locale}
      mode={MAPPING[granularity]}
      selected={activeDate.toJSDate()}
      {...dateBrowserAriaLabels(t, activeDate, formatDate)}
      onChange={handleDateChange}
      dateFormat={granularity === 'Day' ? DATE_COMMON_FORMAT : 'yyyy'}
      maxDate={maxDate}
      minDate={minDate}
    />
  )
}
