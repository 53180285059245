import { useTranslations } from 'next-intl'
import React from 'react'

import type { HeadingLevel } from '@fortum/elemental-ui'
import { Box, ContentText, FontSize, Heading, spacing } from '@fortum/elemental-ui'

import {
  TooltipContainerId,
  getHexFromPositiveNegativeColor,
} from '@/shared/components/SpotPrices/commons'
import type { KPI as KPIType } from '@/shared/components/SpotPrices/commons'
import { TriangleIcon } from '@/shared/components/SpotPrices/Icons'
import { useResponsiveValue } from '@/shared/hooks/useBreakpoints'
import { useTheme } from '@/shared/hooks/useTheme'

export type KPIProps = KPIType & {
  size?: 'regular' | 'large'
  isTooltipContainer?: boolean
}

const SIZES = {
  value: {
    regular: 4,
    large: 2,
  },
  unit: {
    regular: FontSize.xs,
    large: FontSize.m,
  },
  gap: {
    regular: spacing.xxxxs,
    large: spacing.xxxs,
  },
} satisfies Record<string, Record<NonNullable<KPIProps['size']>, string | HeadingLevel>>

export const KPI: React.FC<KPIProps> = ({
  size = 'regular',
  title,
  value,
  unit,
  subtitle,
  color,
  direction,
  isTooltipContainer,
}) => {
  const { colors } = useTheme()
  const t = useTranslations('spotPrices')
  const mappedColor = getHexFromPositiveNegativeColor(color, colors)

  const valueLineHeight = useResponsiveValue({
    default: 1.5,
    s: 44 / 41,
  })
  const valueLargeLineHeight = useResponsiveValue({
    default: 1.5,
    s: 44 / 41,
  })
  const unitLineHeight = useResponsiveValue({
    default: 26 / 12,
    s: 21 / 14,
    l: 1.6,
  })
  const unitLargeLineHeight = useResponsiveValue({
    default: 2.2,
    s: 24 / 16,
    l: 1.75,
  })

  const getDirection = () => {
    switch (direction) {
      case 'up':
        return <TriangleIcon direction="up" mappedColor={mappedColor} />
      case 'down':
        return <TriangleIcon direction="down" mappedColor={mappedColor} />
      default:
        return (
          <Box
            width="0.5rem"
            height="0.5rem"
            borderRadius="100%"
            backgroundColor={colors.inkGrey}
            alignSelf="center"
          />
        )
    }
  }

  const arrayOfSubtitles = Array.isArray(subtitle) ? subtitle : [subtitle]

  return (
    <Box
      data-testid="kpi-container"
      p={{ default: 0 }}
      id={isTooltipContainer ? TooltipContainerId : undefined}
    >
      <Box
        data-testid={direction === 'up' ? 'kpi-high-price' : 'kpi-low-price'}
        display="flex"
        flexDirection="column"
      >
        <Box display="flex" gap={SIZES.gap[size]} alignItems="center">
          {getDirection()}
          <ContentText data-testid="kpi-price-details-time" size={FontSize.s}>
            {size === 'large' ? subtitle : t(title)}
          </ContentText>
        </Box>
        <Box
          data-testid="kpi-price-details"
          display="flex"
          gap={SIZES.gap[size]}
          alignItems="flex-end"
        >
          <Heading
            data-testid="kpi-price-details-amount"
            level={SIZES.value[size]}
            weight="medium"
            color={mappedColor}
            lineHeight={size === 'large' ? valueLargeLineHeight : valueLineHeight}
          >
            {value}
          </Heading>
          {unit && (
            <ContentText
              data-testid="kpi-price-details-unit"
              size={SIZES.unit[size]}
              lineHeight={size === 'large' ? unitLargeLineHeight : unitLineHeight}
            >
              {unit}
            </ContentText>
          )}
        </Box>
        {size === 'regular' && (
          <ContentText
            data-testid={direction === 'up' ? 'kpi-high-price-time' : 'kpi-low-price-time'}
            size={FontSize.xs}
            color={colors.textPrimary}
          >
            {arrayOfSubtitles.map((item, index) => (
              <React.Fragment key={index}>
                {item}
                {arrayOfSubtitles.length !== index + 1 && <br />}
              </React.Fragment>
            ))}
          </ContentText>
        )}
      </Box>
    </Box>
  )
}
