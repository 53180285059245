import { DateTime } from 'luxon'

import { setTZAndLocale } from '@/logged-in/utils/commonUtils'
import type { DateResolution, SpotEntry } from '@/shared/components/SpotPrices/commons'
import { formatMoney } from '@/shared/components/SpotPrices/commons'

export type SpotPriceIndicator = 'highest' | 'lowest' | 'current'

export type SpotPriceTableRow = {
  timestamp: string
  price: string
  id: number
  indicators: SpotPriceIndicator[]
}

const memoizedFormatTimestamp = (() => {
  const cache: Record<string, string> = {}

  return (locale: string, timeZone: string, date: DateTime, resolution: DateResolution): string => {
    const cacheKey = `${date.toISO()}${locale}${timeZone}${resolution}`
    if (cache[cacheKey]) {
      return cache[cacheKey]
    }

    const localDate = setTZAndLocale(date, timeZone, locale)

    const formattedTimestamp =
      resolution === 'Year'
        ? date
            .setLocale(locale)
            .toFormat('LLL')
            .slice(0, 3)
            .replace(/^./, (str) => str.toUpperCase())
        : localDate.toFormat('HH.mm')

    cache[cacheKey] = formattedTimestamp
    return formattedTimestamp
  }
})()

interface ProcessTableRowsParams {
  data?: SpotEntry[]
  highestPrice?: number
  lowestPrice?: number
  timeZone: string
  locale: string
  dateResolution: DateResolution
  isVatIncluded: boolean
  averagePrice?: string
  isDuo?: boolean
}

export const processTableRows = ({
  data,
  highestPrice,
  lowestPrice,
  timeZone,
  locale,
  dateResolution,
  isVatIncluded,
  averagePrice,
  isDuo,
}: ProcessTableRowsParams) => {
  return (data?.map((entry, id) => {
    const dateWithoutMinutes = DateTime.fromJSDate(new Date(entry.time)).set({ minute: 0 })
    const price = isVatIncluded ? entry.total : entry.price
    const averagePriceNumber = Number(averagePrice?.replace(',', '.'))

    const indicators = new Array<SpotPriceIndicator>()

    if (dateWithoutMinutes.setZone(timeZone).hasSame(DateTime.now().setZone(timeZone), 'hour')) {
      indicators.push('current')
    }
    if ((isDuo && Number(price) > averagePriceNumber) || (!isDuo && price === highestPrice)) {
      indicators.push('highest')
    }
    if ((isDuo && Number(price) < averagePriceNumber) || (!isDuo && price === lowestPrice)) {
      indicators.push('lowest')
    }

    const timestamp =
      price !== undefined
        ? memoizedFormatTimestamp(locale, timeZone, dateWithoutMinutes, dateResolution)
        : '--'
    const formatedPrice = price !== undefined ? `${formatMoney(price)} ${entry.unit}` : '--'

    return {
      timestamp,
      price: formatedPrice,
      id,
      indicators,
    }
  }) ?? []) satisfies SpotPriceTableRow[]
}
