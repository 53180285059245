import type React from 'react'
import { useEffect } from 'react'

import { Box, spacing } from '@fortum/elemental-ui'

import { calculateStats } from '@/shared/components/SpotPrices/Graph/calculateStats'
import { Table } from '@/shared/components/SpotPrices/Table'
import { useLocale } from '@/shared/locale'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import { AveragePrice } from './AveragePrice'
import { useIsDuo } from './commons'
import { DownloadButton } from './DownloadButton'
import { Graph } from './Graph'
import { KPIs } from './KPIs'
import { useStore } from './StoreProvider'
import { YearlyGraph } from './YearlyGraph/YearlyGraph'

export const ContentPanel: React.FC = () => {
  const isVatIncluded = useStore((s) => s.isVatIncluded)
  const data = useStore((s) => s.mappedData)
  const bottomTabs = useStore((s) => s.bottomTabs)
  const priceUnit = useStore((s) => s.priceUnit)
  const topTabs = useStore((s) => s.topTabs)
  const setAveragePrice = useStore((s) => s.setAveragePrice)
  const setResolution = useStore((s) => s.setResolution)
  const updateKPIs = useStore((s) => s.updateKPIs)
  const dateResolution = useStore((s) => s.dateResolution)
  const isDuo = useIsDuo()

  const activeBottomTab = bottomTabs.find((t) => t.active)?.id ?? 'graph'
  const activeTopTab = topTabs.find((t) => t.active)?.id ?? 'daily'
  const timeZone = getTimeZoneForPriceAreaCode(useStore((s) => s.priceArea))
  const locale = useLocale()

  useEffect(() => {
    if (!data) {
      return
    }
    const { average, ...KPIs } = calculateStats(
      data,
      timeZone,
      locale,
      dateResolution,
      priceUnit ?? '',
      isVatIncluded,
    )

    updateKPIs(KPIs)
    setAveragePrice(average, priceUnit ?? '')
  }, [
    priceUnit,
    data,
    setAveragePrice,
    updateKPIs,
    timeZone,
    locale,
    dateResolution,
    isVatIncluded,
  ])

  useEffect(() => {
    switch (activeTopTab) {
      case 'daily':
        setResolution('HOUR', 'Day')
        break
      case 'yearly':
        setResolution('MONTH', 'Year')
        break
    }
  }, [activeTopTab, setResolution])

  return (
    <>
      {(() => {
        switch (activeTopTab) {
          case 'daily':
            return (
              <>
                <KPIs showCurrent={true} data-testid="spot-price-kpi" />
                {activeBottomTab === 'graph' ? (
                  <Graph data={data} data-testid="spot-price-graph" />
                ) : (
                  <Box
                    display="flex"
                    flex="1"
                    flexDirection="column"
                    gap={{ default: spacing.xxs, s: spacing.xs }}
                  >
                    <Table data={data} data-testid="spot-price-table" />
                    {isDuo ? (
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <AveragePrice />
                        <DownloadButton data={data} data-testid="download-spot-price" />
                      </Box>
                    ) : (
                      <DownloadButton data={data} data-testid="download-spot-price" />
                    )}
                  </Box>
                )}
                {activeBottomTab !== 'table' && (
                  <AveragePrice data-testid="graph-panel-average-price" />
                )}
              </>
            )
          case 'yearly':
            return (
              <>
                <KPIs data-testid="spot-price-kpi" />
                {activeBottomTab === 'graph' ? (
                  <YearlyGraph data={data} data-testid="spot-price-graph-yearly" />
                ) : (
                  <Box
                    display="flex"
                    flex="1"
                    flexDirection="column"
                    gap={{ default: spacing.xxs, s: spacing.xs }}
                  >
                    <Table data={data} data-testid="spot-price-table" />
                  </Box>
                )}
              </>
            )
          default:
            return null
        }
      })()}
    </>
  )
}
