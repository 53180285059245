'use client'

import { useTranslations } from 'next-intl'
import type { ReactNode } from 'react'
import type React from 'react'
import { createContext, useCallback, useContext, useMemo, useState } from 'react'

import {
  Box,
  ContentText,
  FontSize,
  IconButton,
  IconInfo,
  Popover,
  spacing,
  usePopover,
} from '@fortum/elemental-ui'

import { StyledHeading } from '@/shared/components/StyledHeading'
import { useTheme } from '@/shared/hooks/useTheme'

const Context = createContext<HTMLDivElement | null>(null)

/**
 * This hook is used to capture reference of surrounding/boundary html element to be used as a boundary for Popover
 */
export const useWidgetHeading = () => {
  const [element, setElement] = useState<HTMLDivElement | null>(null)

  const setRef = useCallback((htmlElement: HTMLDivElement | null) => {
    setElement(htmlElement)
  }, [])

  const WidgetHeadingBoundaryProvider = useMemo(() => {
    return function WidgetHeadingBoundaryProvider({ children }: { children: ReactNode }) {
      return <Context.Provider value={element}>{children}</Context.Provider>
    }
  }, [element])

  return { WidgetHeadingBoundaryProvider, setRef, ref: element }
}

type WidgetHeadingProps = {
  heading: string
  popover?: string
  icon?: React.ReactNode
  hideIcon?: boolean
  ariaLabel?: string
  suffix?: React.ReactNode
}

/**
 * Heading component for Widget components - displays title and info icon with popover
 */
export const WidgetHeading: React.FC<WidgetHeadingProps> = ({
  heading,
  popover,
  icon,
  hideIcon,
  ariaLabel,
  suffix,
}) => {
  const { colors } = useTheme()
  const { open: isOpened, anchor, handleOpen, handleClose } = usePopover()
  const [popoverWidth, setPopoverWidth] = useState<number>(0)
  const boundary = useContext(Context)
  const t = useTranslations('ariaLabels')

  const padding = useMemo(() => {
    if (!boundary || typeof window === 'undefined') {
      return 0
    }
    return Number.parseFloat(window.getComputedStyle(boundary).paddingLeft.replace('px', ''))
  }, [boundary])

  const boxRef = (element: HTMLDivElement | null) => {
    if (!element) {
      return
    }
    setPopoverWidth(element.clientWidth)
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      gap={spacing.xxxs}
      boxRef={boxRef}
      onMouseLeave={handleClose}
      onBlur={handleClose}
      position="relative"
    >
      {icon}
      <StyledHeading level={2} styledAs={5} textColor={colors.textLarge}>
        {heading}
      </StyledHeading>
      {!hideIcon && popover != null && (
        <IconButton
          icon={<IconInfo />}
          size="1.5rem"
          status="plain"
          color={colors.textSecondary}
          onMouseEnter={handleOpen}
          onClick={isOpened ? handleClose : handleOpen}
          data-testid="widget-heading-info-icon"
          aria-label={ariaLabel ?? t('infoHeading')}
        />
      )}
      {suffix && (
        <Box marginLeft="auto" alignSelf="flex-start">
          {suffix}
        </Box>
      )}
      <Popover
        disablePortal
        onClose={handleClose}
        opened={isOpened}
        boundary={boundary ?? undefined}
        anchor={anchor}
        popperOffset={14}
        anchorPos="bottom"
        backgroundColor={colors.cushyBlue}
        textColor={colors.snowWhite}
        popperPadding={padding}
        width={830}
        zIndex={88}
      >
        <ContentText
          p={spacing.xxs}
          maxWidth={`${Math.min(popoverWidth, 830)}px`}
          size={FontSize.s}
          data-testid="widget-heading-info-contenttext"
        >
          {popover}
        </ContentText>
      </Popover>
    </Box>
  )
}
