import { DateTime } from 'luxon'

import type { TranslatedSpotPriceStrings } from '@/logged-in/utils/commonUtils'
import { convertToCsv, replaceHyphenWithMinus, setTZAndLocale } from '@/logged-in/utils/commonUtils'
import type { PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'
import { defaultOptions } from '@/shared/hooks/usePriceToStringFormatter'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

import type { SpotEntry } from './commons'
import {
  GRANULARITY_OPTIONS,
  resolutionTimeFormatter,
} from '../../../features/logged-in/components/Energy/commons'

export type SpotGraphContextPoint = Highcharts.Point & {
  y: number | undefined
  priceMissing: boolean
  effectivePrice: number | undefined
} & SpotEntry

type SpotEntryForPrice = Omit<SpotEntry, 'time' | 'unit'>

export const generateSpotPriceCSV = async (
  data: SpotEntry[],
  averagePrice: { value: string; unit: string },
  currentLocale: string,
  priceArea: PriceAreaCode,
  translatedStrings: TranslatedSpotPriceStrings,
  isVatIncluded: boolean,
) => {
  const timeZone = getTimeZoneForPriceAreaCode(priceArea)

  const mappedData = data.map((spotPrice) => {
    const effectivePrice = getEffectivePrice(spotPrice, isVatIncluded)
    const date = setTZAndLocale(spotPrice.time, timeZone, currentLocale)
    const price = isNotNullOrUndefined(effectivePrice)
      ? new Intl.NumberFormat(currentLocale, defaultOptions).format(effectivePrice)
      : ''

    return {
      hour: resolutionTimeFormatter(GRANULARITY_OPTIONS[0], date, false),
      [`${translatedStrings.price} [${spotPrice.unit}]`]: replaceHyphenWithMinus(price),
    }
  })

  const csvString = convertToCsv(
    'Spotprice',
    mappedData,
    translatedStrings,
    [],
    replaceHyphenWithMinus(averagePrice.value),
  )

  return csvString
}

export const getEffectivePrice = (
  entry: SpotEntryForPrice,
  isVatEnabled: boolean,
): number | undefined => {
  return isVatEnabled ? entry.total : entry.price
}

// Compute the processed data for Highcharts (for both daily and yearly)
export const computeSpotGraphData = (
  data: SpotEntry[],
  isVatIncluded: boolean,
): SpotGraphContextPoint[] => {
  return data.map((entry, index) => {
    const effective = getEffectivePrice(entry, isVatIncluded)
    const dataUpToIndex = data
      .slice(0, index + 1)
      .filter((e) => getEffectivePrice(e, isVatIncluded) != null)
    const lastEntryWithData = dataUpToIndex.at(-1) ?? { price: 0, total: 0 }
    const lastEffective = getEffectivePrice(lastEntryWithData, isVatIncluded) ?? 0

    return {
      y: effective ?? lastEffective,
      effectivePrice: effective,
      priceMissing: effective === undefined,
      ...entry,
    } as SpotGraphContextPoint
  })
}

// Compute aggregates: average, min, max, range and whether data is empty.
export const computeAggregates = (data: SpotEntry[], isVatIncluded: boolean) => {
  const values = data.map((entry) => getEffectivePrice(entry, isVatIncluded) ?? 0)
  const total = data.reduce((acc, entry) => acc + (getEffectivePrice(entry, isVatIncluded) ?? 0), 0)
  const average = total / data.length
  const max = Math.max(...values)
  const min = Math.min(...values)
  const range = max - min
  const isEmpty = !data.some((entry) => getEffectivePrice(entry, isVatIncluded) != null)
  return { average, max, min, range, isEmpty }
}

// Compute category labels. For daily config you might use 'HH' or 'HH.mm',
// and for yearly you might use a month format.
export const computeCategories = (
  data: SpotEntry[],
  locale: string,
  formatStr: string,
): string[] => {
  return data.map((entry) => {
    const dt = DateTime.fromJSDate(new Date(entry.time)).setLocale(locale)
    return dt.toFormat(formatStr)
  })
}

export const getColorFromPalette = (
  stops: Highcharts.GradientColorStopObject[],
): Highcharts.GradientColorObject => ({
  linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
  stops,
})

// Generic updateZones that accepts a color resolver callback.
export const updateZones = (
  points: SpotGraphContextPoint[],
  colorResolver: (
    point: SpotGraphContextPoint,
    index: number,
  ) => Highcharts.ColorString | Highcharts.GradientColorObject,
): Highcharts.SeriesZonesOptionsObject[] => {
  return points.map((point, index) => ({
    value: point.index + 1,
    color: colorResolver(point, index),
  }))
}
