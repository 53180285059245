import { DateTime } from 'luxon'

import { formatTimeSpan, setTZAndLocale } from '@/logged-in/utils/commonUtils'
import type { DateResolution, SpotEntry } from '@/shared/components/SpotPrices/commons'
import {
  formatMoney,
  getPositiveNegativeColorFromPrice,
} from '@/shared/components/SpotPrices/commons'
import { getEffectivePrice } from '@/shared/components/SpotPrices/utils'
import { isNotNullOrUndefined } from '@/shared/utils/isNotNullOrUndefined'

export const calculateStats = (
  data: SpotEntry[],
  timeZone: string,
  locale: string,
  dateResolution: DateResolution,
  priceUnit: string,
  isVatIncluded: boolean,
) => {
  // Use getEffectivePrice to produce an array of effective prices.
  const effectivePrices = data
    .map((entry) => getEffectivePrice(entry, isVatIncluded))
    .filter(isNotNullOrUndefined)

  const averagePrice = effectivePrices.reduce((acc, price) => acc + (price ?? 0), 0) / data.length

  const hasPrices = effectivePrices.length > 0
  const lowestPrice = hasPrices ? Math.min(...effectivePrices) : undefined
  const highestPrice = hasPrices ? Math.max(...effectivePrices) : undefined

  // Find the current entry based on the current hour.
  const current = data.find(
    (entry) =>
      DateTime.fromJSDate(new Date(entry.time)).setZone(timeZone).hour ===
      DateTime.now().setZone(timeZone).hour,
  )

  // Filter entries using the effective price.
  const lowestEntries = data
    .filter((entry) => getEffectivePrice(entry, isVatIncluded) === lowestPrice)
    .map((entry) => setTZAndLocale(entry.time, timeZone, locale))

  const highestEntries = data
    .filter((entry) => getEffectivePrice(entry, isVatIncluded) === highestPrice)
    .map((entry) => setTZAndLocale(entry.time, timeZone, locale))

  const getSubtitle = (type: 'lowest' | 'highest') => {
    if (!hasPrices) {
      return ''
    }
    switch (dateResolution) {
      case 'Day':
        return (type === 'lowest' ? lowestEntries : highestEntries).map((entry) =>
          formatTimeSpan(entry),
        )
      case 'Year':
        return getMonthFromEntries(type === 'lowest' ? lowestEntries : highestEntries)
      default:
        return ''
    }
  }

  const getMonthFromEntries = (entries: DateTime[]) => {
    if (entries.length > 0) {
      return entries[0]
        .setLocale(locale)
        .toFormat('MMMM')
        .replace(/^./, (str) => str.toUpperCase())
    }
    return ''
  }

  const currentEffectivePrice = current ? getEffectivePrice(current, isVatIncluded) : undefined

  return {
    current: {
      value: formatMoney(currentEffectivePrice),
      rawValue: currentEffectivePrice,
      color: getPositiveNegativeColorFromPrice(currentEffectivePrice, averagePrice),
      subtitle:
        currentEffectivePrice != null
          ? formatTimeSpan(setTZAndLocale(current!.time, timeZone, locale))
          : '',
      unit: priceUnit,
    },
    lowest: {
      value: formatMoney(lowestPrice),
      rawValue: lowestPrice,
      subtitle: getSubtitle('lowest'),
      unit: priceUnit,
    },
    highest: {
      value: formatMoney(highestPrice),
      rawValue: highestPrice,
      subtitle: getSubtitle('highest'),
      unit: priceUnit,
    },
    average: formatMoney(averagePrice),
  }
}
