import type React from 'react'

import { Box, Loader } from '@fortum/elemental-ui'

import { getTimeZoneForPriceAreaCode } from '@/shared//utils/timezone'
import { ServerSideDefaultWrapper } from '@/shared/components/ServerSideDefaultWrapper'
import Chart from '@/shared/components/SpotPrices/Graph/Chart'
import { useTheme } from '@/shared/hooks/useTheme'
import { isToday, isTomorrow } from '@/shared/utils/dates'

import { useSpotConfig } from './config'
import type { SpotEntry } from '../commons'
import { useStore } from '../StoreProvider'

type SpotGraphProps = {
  data: SpotEntry[]
}

export const Graph: React.FC<SpotGraphProps> = ({ data }) => {
  const activeDate = useStore((s) => s.activeDate)
  const priceArea = useStore((s) => s.priceArea)
  const isVatIncluded = useStore((s) => s.isVatIncluded)
  const timeZone = getTimeZoneForPriceAreaCode(priceArea)

  const dateMode = isToday(activeDate, priceArea)
    ? 'today'
    : isTomorrow(activeDate, priceArea)
      ? 'tomorrow'
      : null
  const config = useSpotConfig(data, dateMode, timeZone, isVatIncluded)
  const { colors } = useTheme()

  return (
    <ServerSideDefaultWrapper
      serverSidePlaceholder={
        <Box
          height={{ default: '257px', m: '268px', l: '264px' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Loader color={colors.oceanGreen} />
        </Box>
      }
    >
      <Chart data-testid="spot-price-chart" config={config} />
    </ServerSideDefaultWrapper>
  )
}
