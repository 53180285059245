import { DateTime } from 'luxon'

import type { SpotEntry } from '@/shared/components/SpotPrices/commons'
import type { PriceAreaCode } from '@/shared/graphql/schema/commonBackend/graphql'
import { getTimeZoneForPriceAreaCode } from '@/shared/utils/timezone'

// A helper function to add a totalPrice property including 25% VAT
const addVat = (prices: SpotEntry[]): SpotEntry[] => {
  return prices.map((entry) => ({
    ...entry,
    total: entry.price !== undefined ? entry.price * 1.25 : undefined,
  }))
}

// Now create a new array with the total price computed:

export const mockPriceArea: PriceAreaCode = 'SE1'

export const MockedSpotPricesNow = DateTime.fromObject({
  year: 2023,
  month: 8,
  day: 21,
  hour: 12,
}).setZone(getTimeZoneForPriceAreaCode(mockPriceArea))

const MockedSpotPricesGapData = [
  {
    time: '2023-08-21 00:00',
    price: 49.47,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 01:00',
    price: 43.09,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 02:00',
    price: 26,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 03:00',
    price: 33.43,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 04:00',
    price: 94.9,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 05:00',
    price: 2.12,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 06:00',
    price: 65.83,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 07:00',
    price: 52.77,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 08:00',
    price: 55.19,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 09:00',
    price: 78.91,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 10:00',
    price: undefined,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 11:00',
    price: 32.11,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 12:00',
    price: 52.49,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 13:00',
    price: undefined,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 14:00',
    price: 69.74,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 15:00',
    price: 33.4,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 16:00',
    price: undefined,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 17:00',
    price: 16.62,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 18:00',
    price: 58.52,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 19:00',
    price: 32.94,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 20:00',
    price: 34.94,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 21:00',
    price: 66.68,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 22:00',
    price: 25.45,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 23:00',
    price: 20.36,
    unit: 'öre/kWh',
  },
] satisfies SpotEntry[]

const MockedSpotPrices = [
  {
    time: '2023-08-21 00:00',
    price: 49.47,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 01:00',
    price: 43.09,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 02:00',
    price: 26,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 03:00',
    price: 33.43,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 04:00',
    price: 94.9,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 05:00',
    price: 2.12,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 06:00',
    price: 65.83,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 07:00',
    price: 52.77,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 08:00',
    price: 55.19,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 09:00',
    price: 78.91,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 10:00',
    price: 8.86,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 11:00',
    price: 32.11,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 12:00',
    price: 52.49,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 13:00',
    price: 33.92,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 14:00',
    price: 69.74,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 15:00',
    price: 33.4,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 16:00',
    price: 59.88,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 17:00',
    price: 16.62,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 18:00',
    price: 58.52,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 19:00',
    price: 32.94,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 20:00',
    price: 34.94,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 21:00',
    price: 66.68,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 22:00',
    price: 25.45,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21 23:00',
    price: 20.36,
    unit: 'öre/kWh',
  },
] satisfies SpotEntry[]

const MockedYearlySpotPrices = [
  {
    time: '2024-01-07',
    price: 20,
    unit: 'öre/kWh',
  },
  {
    time: '2023-02-21',
    price: 25.09,
    unit: 'öre/kWh',
  },
  {
    time: '2023-03-21',
    price: 26,
    unit: 'öre/kWh',
  },
  {
    time: '2023-04-21',
    price: 33.43,
    unit: 'öre/kWh',
  },
  {
    time: '2023-05-21',
    price: 14.47,
    unit: 'öre/kWh',
  },
  {
    time: '2023-06-21',
    price: 14.09,
    unit: 'öre/kWh',
  },
  {
    time: '2023-07-21',
    price: 26,
    unit: 'öre/kWh',
  },
  {
    time: '2023-08-21',
    price: 50.43,
    unit: 'öre/kWh',
  },
  {
    time: '2023-09-21',
    price: 75.47,
    unit: 'öre/kWh',
  },
  {
    time: '2023-10-21',
    price: 80.09,
    unit: 'öre/kWh',
  },
  {
    time: '2023-11-21',
    price: 26,
    unit: 'öre/kWh',
  },
  {
    time: '2023-12-21',
    price: 33.43,
    unit: 'öre/kWh',
  },
]

export const MockedSpotPricesWithVat = addVat(MockedSpotPrices)
export const MockedSpotPricesGapDataWithVat = addVat(MockedSpotPricesGapData)
export const MockedYearlySpotPricesWithVat = addVat(MockedYearlySpotPrices)
