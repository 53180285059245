import { useTranslations } from 'next-intl'
import type React from 'react'

import { Box, ContentText, FontSize, spacing } from '@fortum/elemental-ui'

import { DuoLine } from '@/shared/components/SpotPrices/AveragePrice/DuoLine'
import { useIsDuo } from '@/shared/components/SpotPrices/commons'
import { HyphenIcon, TriangleIcon } from '@/shared/components/SpotPrices/Icons'
import { useTheme } from '@/shared/hooks/useTheme'

import { useStore } from '../StoreProvider'

export const AveragePrice: React.FC = () => {
  const { colors } = useTheme()
  const price = useStore((s) => s.averagePrice)
  const isDuo = useIsDuo()
  const t = useTranslations('spotPrices')
  const tDuo = useTranslations('duoContract.spotPrice')

  if (!isDuo) {
    return (
      <ContentText
        data-testid="daily-average-price"
        display="flex"
        gap={spacing.xxxxs}
        size={FontSize.s}
        height={{ default: '21px', m: '24px' }}
      >
        {`${t('dailyAveragePrice')} ${price.value} ${price.unit}`}
      </ContentText>
    )
  }

  return (
    <Box display="flex" flexDirection="column">
      <DuoLine
        label={tDuo('usageIncreasingConsumptionEffect')}
        icon={<TriangleIcon direction="up" mappedColor={colors['chart2-1']} withoutOffset />}
      />
      <DuoLine
        label={`${tDuo('averageSpotPrice')} ${price.value} ${price.unit}`}
        icon={<HyphenIcon />}
      />
      <DuoLine
        label={tDuo('usageDecreasingConsumptionEffect')}
        icon={<TriangleIcon direction="down" mappedColor={colors['chart6-1']} withoutOffset />}
      />
    </Box>
  )
}
